import { ApiFetchStatus, Subscriptions } from "module/common/helpers/variables";

export enum SubscriptionStatus {
  Active = "Active",
  Canceled = "Canceled",
  Expired = "Expired",
  PastDue = "Past_due",
  Pending = "Pending",
}

export interface ISubscription {
  addOns: any[];
  discount: any[];
  isCancellable: boolean;
  nextBillingDate: string;
  nextBillingPeriodAmount: string;
  taxAmount: number;
  planId: string;
  price: string;
  status: SubscriptionStatus;
}

export enum PlanIds {
  SkipTrace = "skiptrace",
  Free = "free",
  Starter = "starter",
  Core = "core",
  Pro = "pro",
  Enterprise = "enterprise",
}

export interface IPlan {
  id: PlanIds;
  description: string;
  price: number;
  maxPlanPriceColdMessageAttempt: number;
  maxPlanPricePropertyOwnerLookup: number;
  minUnitPriceSkiptrace: number;
  maxMonthsProspectRetention: number;
  maxDailyLimitColdMessageAttempt: number;
  canAccessDncImportExport: boolean;
  canAccessTeamCollaboration: boolean;
  canAccessThirdPartyIntegration: boolean;
}

export interface EstimateSubscriptionCost {
  costNextBill: number;
  costToday: number;
  costTodayWithoutTaxes: number;
  taxAmount: number;
  changeType: "downgrade" | "upgrade" | null;
  datetimeChange: string;
  isProrated: boolean;
  plan: IPlan;
}

export interface IPlanChoices {
  estimates: EstimateSubscriptionCost[];
  replacesPendingChange: boolean;
}

export interface IDowngrade {
  planId: Subscriptions;
  amount: number;
  downgradeFrom: Subscriptions.Core | Subscriptions.Pro | Subscriptions.Enterprise;
  uploads: number;
}

export interface IEntitlements {
  id: number;
  companyId: number;
  plan: IPlan;
  planId: Subscriptions;
  subscription?: ISubscription;
}

export interface IState {
  plainPlanChoices: IPlan[] | null;
  planChoices: IPlanChoices | null;
  entitlements: IEntitlements | null;
  status: ApiFetchStatus;
  entitlementsStatus: ApiFetchStatus;
}
