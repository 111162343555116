import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Timeline } from "../NotesTab/Note";
import { Heading, List } from "../NotesTab/NotesTab";
import { NO_ACTIVITY_FEED } from "module/common/helpers/variables";
import DataLoader from "../../../common/components/DataLoader";
import { useSelector, useDispatch } from "react-redux";
import {
  getActivities,
  getActivitiesStatus,
} from "../../../main/store/ProspectDetails/activities/selectors";
import { fetchActivities } from "../../../main/store/ProspectDetails/activities/thunks";
import { getFormattedDateTime } from "../../../common/helpers/dateUtils";

const ActivityCard = styled.li`
  display: flex;
  text-align: left;
  list-style: none;

  padding-bottom: var(--pad6);

  p {
    line-height: 1.4;
  }
`;

const StyledActivityWrapper = styled.div`
  display: flex;
  min-height: 100%;
  background: var(--white);
`;

function ActivityItem({ id, item }) {
  const { date: createdDate, title, description } = item;
  const dateTime = getFormattedDateTime(createdDate);

  return (
    <ActivityCard id={id} data-test="activity-feed-item">
      <Timeline circleColor="var(--sherpaBlue)" data-test="timeline">
        <div className="circle"></div>
        <div className="grayBar"></div>
      </Timeline>
      <div data-test="note-details">
        <pre className="textL gray">{`${dateTime[0]}  |  ${dateTime[1]}`}</pre>
        <h5 className="textL font-weight-bold">
          {/* 
          Minor workaround to cover the titles of "Added to DNC" and "Removed from DNC",
          since the term we now use is "Company Block List" and changing it in the backend
          would require a lot of changes and might have major impact on the system.
          */}
          {title.replace("DNC", "Company Block List")}
        </h5>
        <p className="textL break-word">{description}</p>
      </div>
    </ActivityCard>
  );
}

function ActivityTab({ prospect }) {
  const activities = useSelector((state) => getActivities(state, prospect.id));
  const status = useSelector(getActivitiesStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!activities && prospect.id) {
      dispatch(fetchActivities(prospect.id));
    }
    // eslint-disable-next-line
  }, [prospect.id]);

  const mapActivity = () =>
    activities?.map((item) => {
      return (
        <React.Fragment key={item.id}>
          <ActivityItem id={item.id} item={item} />
        </React.Fragment>
      );
    }) || [];

  return (
    <StyledActivityWrapper data-test="activity-tab-pane">
      <div className="notes-area mt-4 flex-grow-1">
        <Heading />
        <DataLoader
          status={status}
          data={activities}
          emptyResultsMessage={NO_ACTIVITY_FEED}
          renderData={() => <List>{mapActivity()}</List>}
        />
      </div>
    </StyledActivityWrapper>
  );
}

export default ActivityTab;
