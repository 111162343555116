import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import useModal from "module/common/hooks/useModal";
import { UncontrolledTooltip } from "reactstrap";
import { isMobile } from "../../../../detectDevice";

// components
import NameBubble from "../NameBubble";
import StatusActionBtns from "../StatusActionBtns";
import ReportConfirmationModal from "./ReportConfirmationModal";
import ClickToCall from "../ClickToCall";
// hooks
import useWindowSize from "module/common/hooks/useWindowSize";
// containers
import LeadStagesSelector from "module/common/components/LeadStagesSelector";
import { ProspectSubActionButtons } from ".";
import StatusWrapper from "../UnreadMessagePage/StatusWrapper";
// redux related
import { actionBtnStatusSelector } from "../../../main/store/uiStore/prospectDetailsView/selectors";
import {
  removeUnreadMessages,
  markMessagesAsReadLocally,
} from "../../../main/store/ProspectDetails/messages/thunk";
import { removeCampaignProspect } from "../../../main/store/campaignProspectStore/thunks";
import {
  getProspect,
  prospectIsLoading,
} from "../../../main/store/prospectStore/selectors";
import { uiGetActiveProspect } from "../../../main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/selectors";
import PropertyDataModalLink from "../ProspectSlideOut/PropertyDataModalLink";
import { ReactstrapSpinner } from "module/common/components/ReactstrapSpinner";
import { prospectFetchSingle } from "module/main/store/prospectStore/thunks";

// styled components
const Card = styled.div`
  --padding: var(--pad2);
  position: relative;
  background: white;
  box-shadow: 0 6px 22px -8px #626262;
  z-index: 2;
  border-radius: 5px;
  margin: var(--pad3);

  .lead-stage-input-select {
    flex: 1 1 26%;
  }
  &::before {
    content: "";
    position: absolute;
    top: -1.5rem;
    height: var(--pad3);
    width: 100%;
    z-index: -70;
    background: linear-gradient(to top, #dedede 0%, #f5f5f5 15%, #ffffff 25%);
  }
`;

const StyledRow = styled.div`
  padding-top: var(--pad1);
  padding-bottom: var(--pad1);
  padding-right: var(--pad3);
  padding-left: var(--pad3);
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  .top-lead-stage {
    display: inline;
    @media (max-width: 575.98px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      display: none;
    }
  }
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const StyledStatusButtons = styled.div`
  padding-right: var(--pad3);
  padding-left: var(--pad3);
  padding-top: var(--pad2);
  padding-bottom: var(--pad2);
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .status-actions,
  .bottom-lead-stage {
    display: none;
    @media (max-width: 575.98px) {
      display: inline;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      display: inline;
    }
  }

  .status-actions-wrapper {
    display: inline;
    @media (max-width: 575.98px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      display: none;
    }
  }

  @media (max-width: 575.98px) {
    padding-top: 0;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    padding-top: 0;
  }
`;

const StyledCardDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: var(--pad3) 0 var(--pad2) var(--pad3);
`;

const StyledCardDetailsWrapper = styled.div`
  flex-grow: 1;
`;

const StyledCardAddress = styled.p`
  margin-bottom: 0.25rem;
`;

const StyledH4 = styled.h4`
  margin-bottom: 0.25rem;
`;

const StyledPhoneDisplay = styled.div`
  margin-bottom: 0.25rem;
`;

const ZillowLink = styled.a`
  display: inline;
  white-space: pre;
  svg {
    margin-right: 0.3em;
  }
`;

const StyledNameAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767.98) {
    flex-direction: column-reverse;
  }
`;

const StyledSubActionButtons = styled.div`
  text-align: right;
`;

const StyledNameBubble = styled(NameBubble)`
  @media (max-width: 767.98) {
    margin-top: var(--pad5);
  }
`;

const ProspectCard = (props) => {
  const [isReportConfirmationModalOpen, , toggleReportConfirmationModal] = useModal();
  const windowSize = useWindowSize();
  // selectors
  const prospectId = useSelector(uiGetActiveProspect);
  const actionBtnStatus = useSelector(actionBtnStatusSelector);
  const isProspectLoading = useSelector(prospectIsLoading);
  const uiProspect = useSelector((state) => getProspect(state, prospectId));
  const dispatch = useDispatch();

  const prospect = props.prospect || uiProspect;

  const {
    id = 0,
    ownerVerifiedStatus = "",
    doNotCall = false,
    isQualifiedLead = false,
    isPriority = false,
    wrongNumber = false,
    partial,
    optedOut,
  } = prospect;

  const dncActions = doNotCall
    ? []
    : [
        () => dispatch(removeUnreadMessages(id, prospect)),
        () => dispatch(removeCampaignProspect(id, prospect.hasUnreadSms)),
      ];

  const statusList = [
    {
      text: "Verified",
      color: "green",
      icon: "check",
      active: ownerVerifiedStatus === "verified",
      attr: "ownerVerifiedStatus",
      status: actionBtnStatus.ownerVerifiedStatus,
    },
    {
      text: "Block List",
      color: "red",
      icon: "phone-slash",
      active: doNotCall,
      attr: "doNotCall",
      disabled: optedOut,
      status: actionBtnStatus.doNotCall,
      actions: dncActions,
    },
    {
      text: "Priority",
      color: "orange",
      icon: "bolt",
      active: isPriority,
      attr: "isPriority",
      status: actionBtnStatus.isPriority,
    },
    {
      text: "Qualified",
      color: "purple",
      icon: "star",
      active: isQualifiedLead,
      attr: "isQualifiedLead",
      status: actionBtnStatus.isQualifiedLead,
    },
    {
      text: "Wrong Number",
      color: "warning",
      icon: "phone",
      active: wrongNumber,
      attr: "wrongNumber",
      status: actionBtnStatus.wrongNumber,
      actions: [() => dispatch(markMessagesAsReadLocally(id, prospect))],
    },
  ];

  useEffect(() => {
    if (partial) {
      dispatch(prospectFetchSingle(id));
    }
    // eslint-disable-next-line
  }, [partial, id]);

  const zillowLink = (
    <ZillowLink href={prospect.zillowLink} rel="noopener noreferrer" target="_blank">
      View property on Zillow <FontAwesomeIcon icon="external-link-alt" />
    </ZillowLink>
  );

  const additionalPropertyDataModalLink = (
    <PropertyDataModalLink id={prospect.propertyId}>
      {(toggle) => (
        <ZillowLink href="#" onClick={toggle}>
          <FontAwesomeIcon icon="home" /> View additional property info
        </ZillowLink>
      )}
    </PropertyDataModalLink>
  );

  const prospectHeaderName = (
    <StyledH4 data-test="prospect-card-header">
      {prospect.firstName} {prospect.lastName}
    </StyledH4>
  );

  const phoneDisplay = (
    <StyledPhoneDisplay className="darkGray" data-test="phone-display">
      {prospect.phoneDisplay} ({prospect.phoneType})
      <FontAwesomeIcon
        id="block-prospect-icon"
        icon="ban"
        color={prospect.isBlocked ? "red" : "var(--darkGray)"}
        className="ml-1 pointer fa-sm"
        data-test="report-button"
        onClick={toggleReportConfirmationModal}
      />
      <UncontrolledTooltip placement="bottom" target="block-prospect-icon">
        {prospect.isBlocked ? "Blocked" : "Block prospect"}
      </UncontrolledTooltip>
    </StyledPhoneDisplay>
  );

  const addressDisplay = (
    <StyledCardAddress className="darkGray d-block" data-test="address-display">
      {prospect.addressDisplay}
    </StyledCardAddress>
  );

  if (props.modalHeader)
    return (
      <StyledCardDetails className="textL pb-0" data-test="modal-header-card">
        <NameBubble
          className="mr-2"
          size="5rem"
          initials={`${prospect.firstName?.[0] || ""}${prospect.lastName?.[0] || ""}`}
        />
        <div className="d-flex flex-column">
          {prospectHeaderName}
          {phoneDisplay}
          {addressDisplay}
        </div>
        <ReportConfirmationModal
          isOpen={isReportConfirmationModalOpen}
          toggle={toggleReportConfirmationModal}
          prospect={prospect}
        />
      </StyledCardDetails>
    );

  if (isProspectLoading || partial) {
    return (
      <Card data-test="prospect-card-spinner">
        <StyledCardDetails>
          <ReactstrapSpinner size="md" />
        </StyledCardDetails>
      </Card>
    );
  }

  return (
    <Card data-test="prospect-card">
      <ReportConfirmationModal
        isOpen={isReportConfirmationModalOpen}
        toggle={toggleReportConfirmationModal}
        prospect={prospect}
      />
      <StyledCardDetails>
        <StyledNameBubble
          className="mr-2"
          size="3.5rem"
          data-test="name-bubble"
          initials={`${prospect.firstName?.[0] || ""}${prospect.lastName?.[0] || ""}`}
        />
        <StyledCardDetailsWrapper data-test="details-wrapper">
          <div>
            <StyledNameAndButtons>
              {prospectHeaderName}
              <StyledSubActionButtons>
                <ProspectSubActionButtons
                  prospect={prospect}
                  onProspectSlideoutClick={props.showProspectPopOver}
                />
              </StyledSubActionButtons>
            </StyledNameAndButtons>
            {phoneDisplay}
            {addressDisplay}
            <div className="pr-4 d-flex flex-row justify-content-between">
              {additionalPropertyDataModalLink}
              {props.fromSequence && (
                <ClickToCall
                  iconOnly={
                    !isMobile && windowSize.width > 767 && windowSize.width < 991.98
                  }
                  prospect={prospect}
                  disabled={prospect.doNotCall}
                />
              )}
            </div>
          </div>
        </StyledCardDetailsWrapper>
      </StyledCardDetails>
      <hr className="mb-0 mt-0" />
      <div className={`${props.fromSequence ? "d-none" : ""}`}>
        <StyledRow>
          <StyledCol>{prospect.zillowLink && zillowLink}</StyledCol>
          <StyledCol data-test="lead-stage-input-select" className="top-lead-stage">
            <LeadStagesSelector
              styles={{
                menu: (provided) => ({
                  ...provided,
                  width: "130%",
                  left: "unset",
                  right: "0",
                  maxWidth: "500px",
                }),
              }}
              prospect={prospect}
              hasManageLeadStagesFunc
            />
          </StyledCol>
        </StyledRow>
        <StyledStatusButtons data-test="status-action">
          <div className="status-actions-wrapper">
            <StatusActionBtns prospect={prospect} statusList={statusList} />
          </div>
          <div className="status-actions">
            <StatusWrapper prospectId={prospect.id}></StatusWrapper>
          </div>
          <StyledCol className="bottom-lead-stage" data-test="lead-stage-input-select">
            <LeadStagesSelector
              styles={{
                menu: (provided) => ({
                  ...provided,
                  width: "130%",
                  left: "unset",
                  right: "0",
                  maxWidth: "500px",
                }),
              }}
              prospect={prospect}
              hasManageLeadStagesFunc
            />
          </StyledCol>
        </StyledStatusButtons>
      </div>
    </Card>
  );
};

export default ProspectCard;
