import { ICreatedBy } from "../Global/interfaces";
import { IPurchaseEstimate } from "../interfaces";

export interface IExpiringSoon {
  dateExpiration: string;
  hitsExpiring: number;
  newPrice: number;
  hitsToKeepUnitPrice: number;
}

interface TierStatus {
  cumulativeHits: number;
  currentTierUnitPrice: number;
  expiringSoon: IExpiringSoon | null;
  hitsToNextUnitPrice: number;
  progressToNextUnitPrice: number;
  nextUnitPrice: number;
}

export interface TiersByType {
  unitPrice: number;
  threshold: number | null;
}

export interface UnitPriceTierInfo {
  currentUnitPrice: number;
  tierStatus: TierStatus;
  tiersByAccount: TiersByType[];
  tiersByFile: TiersByType[];
  capacity: {
    propertyOwnerLookup: number;
  };
  cumulativeBillableHits: number;
}

export interface IParams {
  market: number;
  ordering: string;
}

export enum SkipTraceStatus {
  AutoStop = "auto_stop",
  Complete = "complete",
  Error = "error",
  Cancelled = "cancelled",
  Paused = "paused",
  Preprocessing = "preprocessing",
  Running = "running",
  SentToTask = "sent_to_task",
  Setup = "setup",
  ReadyToBill = "ready_to_bill",
  Billing = "billing",
}

export enum BillingReasons {
  Completed = "completed",
  Cancelled = "cancelled",
  NoRetriesLeft = "no_retries_left",
  AuthExpiration = "auth_expiration",
}

export interface IOrder {
  amountFulfilled: number | null;
  taxAmountFulfilled: number;
}

export interface ISkipTrace {
  id: number;
  cost: string;
  existingMatchSavings: string;
  hitRate: number;
  order?: IOrder;
  lastRowPreprocessed: number;
  lastRowProcessed: number;
  totalRows: number;
  uploadedFilename: string | null;
  uploadStart: string;
  uploadEnd: string;
  status: SkipTraceStatus;
  billingReason: BillingReasons | null;
  resubmitNotAllowedReason: string | null;
  totalExistingMatches: number;
  totalDuplicates: number | null;
  totalHits: number;
  totalBillableHits: number;
  uploadError: null | string;
  createdBy: ICreatedBy;
  company: number;
  suppressAgainstDatabase: boolean;
  propertyTags: number[];
  purchaseEstimate: IPurchaseEstimate;
  pushToCampaignStatus: string;
  pushToCampaignPercent: number;
  pushToCampaignCampaignId: string;
  pushToCampaignCampaignName: string | null;
}

export type UploadSkipTraceData = Pick<
  ISkipTrace,
  "id" | "uploadedFilename" | "totalRows" | "propertyTags" | "purchaseEstimate"
>;

export interface IState {
  isPolling: boolean;
  refetch: boolean;
  activeMarket: string | null;
  sortOrder: number[];
  skipTraces: Record<string, ISkipTrace>;
  running: any[];
  error?: string;
  next?: string;
  previous?: string;
  count: number;
  status: string;
  isLoadingMore: boolean;
}
