import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store/hooks";
import * as Sentry from "@sentry/browser";
// selectors
import {
  selectLoggingInStatus,
  selectAuthStatus,
  selectAuthToken,
  selectVerificationStatus,
} from "module/main/store/Auth/selectors";
import { getMessageCountPollingStatus } from "module/main/store/uiStore/allUnreadPageView/selectors";
// utils
import { setUserIdAndMatchingPixel, tosAgreementModal } from "./utils";
import "assets/fontAwesome/index";
import "./App.css";
import {
  Success,
  Fetching,
  MESSAGES_POLLING_INTERVAL,
  ApiFetchStatus,
} from "module/common/helpers/variables";
import { getLeadStagesList } from "module/main/store/leadstages/thunk";
// store
import { addNewModal } from "../store/Global/Modals/actions";
import { fetchPropertyTags } from "module/main/store/Tags/property/thunks";
import { fetchAllReminders } from "module/main/store/SequencesStore/reminders/thunks";
import { fetchCompanyFeatures } from "module/main/store/Company/features/thunks";
import { fetchMarkets } from "../store/Markets/thunks";
import {
  getRegistrationStatus10DLC,
  getCompanyPaymentInfo,
  callGetCaptureSmsInterest,
} from "module/main/store/Company/thunks";
import { callPostVerifySession, fetchUserInfo } from "module/main/store/Auth/thunks";
import {
  getUnreadMessagesCount,
  campaignUnreadPollingThunk,
} from "module/main/store/campaignProspectStore/thunks";
import { unreadTabActivateMessagesPolling } from "module/main/store/uiStore/allUnreadPageView/actions";
// interface
import { UserData } from "../store/Auth/interfaces";
import { ICompany } from "../store/Company/interfaces";

interface Props {
  hasAuthToken: boolean;
  userData: UserData;
  company: ICompany;
}

export const useInitialDataLoad = ({ hasAuthToken, userData, company }: Props) => {
  const dispatch = useAppDispatch();
  // selectors
  const isLoggingIn = useSelector(selectLoggingInStatus);
  const authStatus = useSelector(selectAuthStatus);
  const authToken = useSelector(selectAuthToken);
  const verificationStatus = useSelector(selectVerificationStatus);
  const activateMessageCountPolling = useSelector(getMessageCountPollingStatus);
  // state
  const [initialMessageFetch, setInitialMessageFetch] = useState(false);

  const isAuthenticated = verificationStatus === ApiFetchStatus.Success;
  const initialRequiredData = {
    marketLoaded: false,
    featuresLoaded: false,
  };
  const [requiredData, setRequiredData] = useState(initialRequiredData);

  useEffect(() => {
    if (!hasAuthToken) return;
    dispatch(callPostVerifySession(authToken));
  }, []);

  useEffect(() => {
    if (isAuthenticated && !userData.id && !isLoggingIn) {
      // fetch auth-data on reload
      dispatch(fetchUserInfo()).then(({ company, email } = {}) => {
        company && dispatch(getCompanyPaymentInfo(company.id));
        if (company && company.latestSmsSubscriptionRequestId) {
          dispatch(callGetCaptureSmsInterest(company.id));
        }
        // tracking code ****
        setUserIdAndMatchingPixel(email);
        // **** tracking code
      });
    }

    if (isAuthenticated && userData.id) {
      Sentry.setUser({ email: userData.email, id: userData.id.toString() });
      if (!userData.profile.hasLatestAgreement) dispatch(addNewModal(tosAgreementModal));
    }
    // Remove all download stats during reload
    const downloadKeys = Object.keys(window.localStorage).filter(
      (key) => key.indexOf("download") === 0
    );
    downloadKeys.forEach((key) => window.localStorage.removeItem(String(key)));
  }, [dispatch, isAuthenticated, userData.id, authStatus, isLoggingIn, userData.email]);

  useEffect(() => {
    if (isAuthenticated) {
      Promise.allSettled([
        dispatch(fetchMarkets()),
        dispatch(getRegistrationStatus10DLC()),
      ]).finally(() =>
        setRequiredData((data) => ({
          ...data,
          marketLoaded: true,
        }))
      );
      dispatch(getLeadStagesList());
      dispatch(fetchPropertyTags());
    } else {
      setInitialMessageFetch(false);
      setRequiredData(initialRequiredData);
    }
  }, [isAuthenticated, dispatch, verificationStatus]);

  useEffect(() => {
    if (isAuthenticated && company.id) {
      dispatch(fetchCompanyFeatures(company.id)).then(() =>
        setRequiredData((data) => ({ ...data, featuresLoaded: true }))
      );
    }
  }, [isAuthenticated, company.id, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(fetchAllReminders());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;

    if (isAuthenticated && activateMessageCountPolling) {
      // deactivate message polling
      dispatch(unreadTabActivateMessagesPolling(false));

      const fetchMessages = () => {
        dispatch(getUnreadMessagesCount());
      };

      if (!initialMessageFetch) {
        // fetch initial bulk of messages
        dispatch(campaignUnreadPollingThunk());
        setInitialMessageFetch(true);
      }
      interval = setInterval(fetchMessages, MESSAGES_POLLING_INTERVAL);
    }
    return () => clearInterval(interval);
  }, [dispatch, activateMessageCountPolling, isAuthenticated, initialMessageFetch]);

  useEffect(() => {
    const onUnhandled = (event: PromiseRejectionEvent) => {
      Sentry.withScope((scope) => {
        scope.setExtra("Reason", event?.reason || null);
        scope.setExtra("Promise", event?.promise || null);
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureMessage("Unhandled non-error promise rejection detected");
      });
      // prevent barebone error bubbling and warn with event for dev
      //eslint-disable-next-line
      console.warn("Unhandled non-error promise rejection:", event);
      event.preventDefault();
    };
    window.addEventListener("unhandledrejection", onUnhandled);
  }, []);

  const allRequiredDataLoaded = Object.values(requiredData).every((isLoaded) => isLoaded);
  if (
    !hasAuthToken ||
    (isAuthenticated && authStatus === Success && userData.id && allRequiredDataLoaded)
  ) {
    return Success;
  }
  return Fetching;
};
