import React from "react";
import { postUpdateTosAgreement } from "../store/Company/api";
import { IModal } from "../store/Global/Modals/interfaces";
import * as Sentry from "@sentry/browser";
import { AppEnv, TrackerMode } from "module/common/helpers/variables";
import { globalDynamicConfig } from "dynamicConfig";

const facebookPixelId = "3454686674581908";

const hashEmailForPixel = async (email: string) => {
  const encodedEmail = new TextEncoder().encode(email);
  // this will only work with https
  const buffer = await crypto.subtle.digest("SHA-256", encodedEmail);
  const hashArray = Array.from(new Uint8Array(buffer));

  return hashArray.map((bytes) => bytes.toString(16).padStart(2, "0")).join("");
};

interface MyWindow extends Window {
  gtag?: (
    method: string,
    options: {
      [user_id: string]: string;
    }
  ) => void;
  fbq?: (
    method: string,
    pixel: string,
    options: {
      [em: string]: string;
    }
  ) => void;
}

export const setUserIdAndMatchingPixel = async (email: string) => {
  if (!email) return;

  try {
    const { gtag, fbq } = window as MyWindow;
    const hashedEmail = await hashEmailForPixel(email);

    if (!hashedEmail) return;

    gtag &&
      gtag("set", {
        user_id: hashedEmail,
      });
    fbq &&
      fbq("init", facebookPixelId, {
        em: hashedEmail,
      });
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        message: `Failed to set User ID`,
      },
    });
  }
};

const addScriptToHead = (...params: { [key: string]: any }[]): void => {
  for (const param of params) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    Object.assign(script, param);
    document.head.append(script);
  }
};

const addHubSpotCode = () => {
  const id = "hs-script-loader";
  const src = "//js.hs-scripts.com/19538966.js";
  addScriptToHead({ src, id, async: true, defer: true });
};

const addFacebookPixelCode = () => {
  const text = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${facebookPixelId});
    fbq('track', 'PageView');
  `;

  addScriptToHead({ text });

  const pixelScriptImg = document.createElement("img");
  pixelScriptImg.style.height = "1px";
  pixelScriptImg.style.width = "1px";
  pixelScriptImg.style.display = "none";
  pixelScriptImg.src =
    "https://www.facebook.com/tr?id=3454686674581908&ev=PageView&noscript=1";

  document.body.append(pixelScriptImg);
};

const addLinkedInPixelCode = () => {
  const partnerText = `
    _linkedin_partner_id = "3846588";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
`;

  const globalText = `
    (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})(window.lintrk);
`;
  addScriptToHead({ text: partnerText }, { text: globalText });
};

const addGoogleAnalyticsPixelCode = (gtagId: string) => {
  const src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;

  const text = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${gtagId}');
    `;

  addScriptToHead({ src, async: true }, { text });
};

export const addAllProdPixelScripts = () => {
  if (
    process.env.NODE_ENV !== AppEnv.Production ||
    globalDynamicConfig.config.REACT_APP_DYNAMIC_TRACKERS_MODE === TrackerMode.NoTrackers
  )
    return;

  addHubSpotCode();
  addFacebookPixelCode();
  addLinkedInPixelCode();
  // GA v4
  addGoogleAnalyticsPixelCode("G-QFEHGDLD2B");
  // Google Ads
  addGoogleAnalyticsPixelCode("AW-611230146");
};

export const tosAgreementModal: IModal = {
  type: "Warning",
  id: "user-tos-agreement",
  title: "New Terms and Condition",
  message: (
    <div className="text-center">
      <p className="mb-3">
        Our{" "}
        <a
          href="https://leadsherpa.com/terms-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>{" "}
        have been updated. Please review and accept them before proceeding.
      </p>
    </div>
  ),
  showClose: false,
  btnOptions: [
    {
      name: "Accept",
      color: "primary",
      onClick: async (closeModal: () => void) => {
        await postUpdateTosAgreement();
        closeModal();
      },
    },
  ],
  open: true,
};
