import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useInterval from "module/common/hooks/useInterval";

import { addNewToast, modifyToast, removeToast } from "../../store/Global/Toasts/actions";

// Exports
import { getExportUUIDs } from "../../store/Global/exports/selectors";
import { deleteExportUUID } from "../../store/Global/exports/actions";
import { pollExports } from "../../store/Global/exports/api";
import { downloadExportFile } from "./utils";

// Uploads
import { getAllUploads } from "../../store/Global/uploads/selectors";
import { deleteUpload } from "../../store/Global/uploads/actions";
import { pollDNCUploads, pollRemoveDNCUploads } from "../../store/Global/uploads/api";
import { IUpload, UPLOAD_TYPES } from "../../store/Global/uploads/interfaces";
import { formatNumber } from "module/common/helpers/utils";

const FilePolling = () => {
  const dispatch = useDispatch();

  // Exports
  const [exportInterval, setExportInterval] = useState<number | null>(null);
  const exportUUIDs: string[] = useSelector(getExportUUIDs);

  const exportDescription: any = {
    campaign_prospect: "campaign prospect",
    skiptrace: "skip trace",
    skiptrace_zip: "skip trace zip",
    skiptrace_contacts: "skip trace contacts",
    prospect: "prospect",
    dnc: "DNC",
    campaign: "campaign",
    campaign_meta_stats: "campaign meta stats",
    profile_stats: "profile stats",
    property: "property",
  };

  useEffect(() => {
    if (exportUUIDs.length > 0) {
      setExportInterval(4000);
    } else {
      setExportInterval(null);
    }
  }, [exportUUIDs]);

  useInterval(() => {
    let hasNotifiedError = false;
    exportUUIDs.forEach(async (uuid) => {
      try {
        const { status, file, downloadType, percentage } = await pollExports(uuid);
        const exportName = exportDescription[downloadType] || "";
        if (status === "complete") {
          dispatch(removeToast({ id: uuid }));
          dispatch(
            addNewToast({
              message: `Your ${exportName} export is ready.`,
              color: "success",
            })
          );
          dispatch(deleteExportUUID(uuid));
          downloadExportFile(file, downloadType);
        } else {
          dispatch(
            modifyToast({
              id: uuid,
              message: `Your ${exportName} export is generating... ${parseInt(
                percentage
              )}%`,
            })
          );
        }
      } catch (err) {
        dispatch(deleteExportUUID(uuid));
        if (!hasNotifiedError) {
          dispatch(removeToast({ id: uuid }));
          dispatch(
            addNewToast({
              message: "An error occurred while generating your file(s).",
              color: "danger",
            })
          );
          hasNotifiedError = true;
        }
      }
    });
  }, exportInterval);

  // Uploads
  const [uploadInterval, setUploadInterval] = useState<number | null>(null);
  const uploads: IUpload[] = useSelector(getAllUploads);

  useEffect(() => {
    if (uploads.length > 0) {
      setUploadInterval(4000);
    } else {
      setUploadInterval(null);
    }
  }, [uploads]);

  useInterval(() => {
    let hasNotifiedError = false;
    uploads.forEach(async (upload) => {
      let poll = null;
      let description = "";
      switch (upload.type) {
        case UPLOAD_TYPES.REMOVE_DNC:
          poll = pollRemoveDNCUploads;
          description = "Company Block List Bulk Remove";
          break;
        case UPLOAD_TYPES.DNC:
          poll = pollDNCUploads;
          description = "Company Block List Bulk Import";
          break;
        default:
          break;
      }
      if (poll === null) {
        return;
      }
      try {
        const { status, percentage } = await poll(upload.id);
        if (status === "complete") {
          dispatch(removeToast({ id: upload.id }));
          dispatch(
            addNewToast({
              message: `Your ${description} has finished importing.`,
              color: "success",
            })
          );
          dispatch(deleteUpload(upload.id));
        } else {
          dispatch(
            modifyToast({
              id: upload.id,
              message: `Your ${description} upload is importing... ${formatNumber({
                value: percentage,
                isPercentage: true,
              })}`,
            })
          );
        }
      } catch (err) {
        dispatch(deleteUpload(upload.id));
        if (!hasNotifiedError) {
          dispatch(removeToast({ id: upload.id }));
          dispatch(
            addNewToast({
              message: "An error occurred while importing your file(s).",
              color: "danger",
            })
          );
          hasNotifiedError = true;
        }
      }
    });
  }, uploadInterval);

  return null;
};

export default FilePolling;
