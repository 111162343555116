import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

// components
import StatusActionBtns from "module/campaign/components/StatusActionBtns";
import LeadStagesSelector from "module/common/components/LeadStagesSelector";

// redux related
import { removeUnreadMessages } from "module/main/store/ProspectDetails/messages/thunk";
import { removeCampaignProspect } from "module/main/store/campaignProspectStore/thunks";

const StyledStatusActionBtnsContainer = styled.div`
  flex: 0 0 70%;
`;
const StyledLeadStagesSelectorContainer = styled.div`
  flex: 0 0 30%;
`;

const ProspectStatusActions = (props) => {
  const { prospect } = props;
  const dispatch = useDispatch();

  const {
    id,
    ownerVerifiedStatus,
    doNotCall,
    isPriority,
    isQualifiedLead,
    wrongNumber,
    optedOut,
  } = prospect;

  const dncActions = doNotCall
    ? []
    : [
        () => dispatch(removeUnreadMessages(id, prospect)),
        () => dispatch(removeCampaignProspect(id, prospect.hasUnreadSms)),
      ];
  const statusList = [
    {
      text: "Verified",
      color: "green",
      icon: "check",
      active: ownerVerifiedStatus === "verified",
      attr: "ownerVerifiedStatus",
    },
    {
      text: "Block List",
      color: "red",
      icon: "phone-slash",
      active: doNotCall,
      attr: "doNotCall",
      disabled: optedOut,
      actions: dncActions,
    },
    {
      text: "Priority",
      color: "orange",
      icon: "bolt",
      active: isPriority,
      attr: "isPriority",
    },
    {
      text: "Qualified",
      color: "purple",
      icon: "star",
      active: isQualifiedLead,
      attr: "isQualifiedLead",
    },
    {
      text: "Wrong Number",
      color: "warning",
      icon: "phone",
      active: wrongNumber,
      attr: "wrongNumber",
    },
  ];

  return (
    <div className="d-flex">
      <StyledStatusActionBtnsContainer>
        <StatusActionBtns
          prospect={prospect}
          statusList={statusList}
          resource="smartStacker"
          className="statusBtn mr-1"
        />
      </StyledStatusActionBtnsContainer>
      <StyledLeadStagesSelectorContainer>
        <LeadStagesSelector prospect={prospect} />
      </StyledLeadStagesSelectorContainer>
    </div>
  );
};
export default ProspectStatusActions;
