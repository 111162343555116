import { produce } from "immer";
import { combineReducers } from "redux";

// reducers
import billing from "./billing/reducer";
import profiles from "./profiles/reducer";
import stats from "./stats/reducer";
import paymentInfo from "./paymentInfo/reducer";
import features from "./features/reducer";

// interfaces
import { ICompany } from "./interfaces";
import { IPlan } from "../SubscriptionStore/interfaces";

// actions
import {
  COMPANY_INCREMENT_MESSAGES_SENT_TODAY,
  COMPANY_UPDATE,
  UPDATE_COMPANY_BRAND_STATUS,
  UPDATE_COMPANY_TEMP_BRAND_STATUS,
  UPDATE_USER_FEATURE_NOTIFICATION,
} from "./actions";
import { PlanIds } from "../SubscriptionStore/interfaces";

export const initialFreePlan: IPlan = {
  id: PlanIds.Free,
  description: "Free",
  price: 0,
  maxPlanPriceColdMessageAttempt: 0,
  maxPlanPricePropertyOwnerLookup: 0,
  minUnitPriceSkiptrace: 0,
  maxMonthsProspectRetention: 0,
  maxDailyLimitColdMessageAttempt: 0,
  canAccessDncImportExport: false,
  canAccessTeamCollaboration: false,
  canAccessThirdPartyIntegration: false,
};

const initial: ICompany = {
  id: 0,
  name: "",
  timezone: "",
  autoDeadEnabled: false,
  isMessagingDisabled: false,
  totalSkipTraceSavings: 0,
  availableBalance: "0",
  replenishmentAmount: "0",
  sherpaBalance: 0,
  postcardPrice: 0,
  telephonyConnections: [],
  unmanagedMarketProspectUnitPrice: "",
  customerId: "",
  coldMessagesAttemptedTodayCount: 0,
  coldMessagesAttemptedThisMonthCount: 0,
  maxDailyLimitColdMessageAttempt: 0,
  maxMonthlyLimitColdMessageAttempt: 0,
  isBillingExempt: false,
  defaultAlternateMessage: null,
  recordCalls: false,
  autoFilterMessages: false,
  startTime: "",
  endTime: "",
  sendCarrierApprovedTemplates: false,
  tcrRegistrationActionRequired: null,
  outgoingCompanyNames: [],
  outgoingUserNames: [],
  invitationCode: {},
  referralCode: null,
  referralLink: null,
  featureNotifications: {},
  autoVerifyProspects: false,
  prospectCount: 0,
  cancellationRequestStatus: {
    pending: false,
    pause: false,
    newPlan: "",
  },
  appSettings: {
    dateFormat: "MM/DD/YYYY",
  },
  integrations: {
    podio: null,
  },
  isRegistered10DLC: null,
  brandStatus: [],
  tempBrandStatus: [],
  smsOptInAttestation: null,
  smsOptInAttestationId: null,
  plan: initialFreePlan,
  maximumInitialMessagesDaily: 0,
  minimumInitialMessagesDaily: 0,
  capacityStep: 0,
  isBeta: false,
  refreshExistingRecordsMonthsDefault: null,
  latestSmsSubscriptionRequest: null,
  latestSmsSubscriptionRequestId: null,
  useScribeSherpa: false,
  skipTraceUnitPrice: 0,
};

const reducer = produce((draft: ICompany, action: any) => {
  switch (action.type) {
    case COMPANY_INCREMENT_MESSAGES_SENT_TODAY: {
      draft.coldMessagesAttemptedTodayCount += 1;
      break;
    }
    case COMPANY_UPDATE: {
      draft = Object.assign(draft, action.payload);
      break;
    }
    case UPDATE_USER_FEATURE_NOTIFICATION: {
      draft.featureNotifications[action.payload.featureNotificationId] = {
        ...draft.featureNotifications[action.payload.featureNotificationId],
        ...action.payload,
      };
      break;
    }
    case UPDATE_COMPANY_BRAND_STATUS: {
      draft.brandStatus = [Object.assign(draft.brandStatus[0] || {}, action.payload)];
      break;
    }
    case UPDATE_COMPANY_TEMP_BRAND_STATUS: {
      draft.tempBrandStatus = [
        Object.assign(draft.tempBrandStatus[0] || {}, action.payload),
      ];
      break;
    }
  }
}, initial);

export const rootPath = ["Company"];
export const statsPath = ["Company", "stats", "stats"];
export const metaStatsPath = ["Company", "stats", "meta_stats"];
export const paymentPath = ["Company", "paymentInfo"];

const root = combineReducers({
  company: reducer,
  billing,
  profiles,
  stats,
  paymentInfo,
  features,
});

export default root;
