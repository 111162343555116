import React from "react";
import IconBg from "module/common/components/IconBg";
import styled from "styled-components/macro";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { actionBtnStatusSelector } from "../../../main/store/uiStore/prospectDetailsView/selectors";
import { getProspect } from "../../../main/store/prospectStore/selectors";
import {
  getSingleCallRemindersProspects,
  getSingleSmsRemindersProspects,
} from "../../../main/store/SequencesStore/reminders/selectors";
import { prospectUpdateStatus } from "../../../main/store/prospectStore/thunks";
import { getNewVerifiedStatus } from "../StatusActionBtns";
import {
  removeUnreadMessages,
  markMessagesAsReadLocally,
} from "../../../main/store/ProspectDetails/messages/thunk";
import { removeCampaignProspect } from "../../../main/store/campaignProspectStore/thunks";
import { getFormattedDateTime } from "../../../common/helpers/dateUtils";

const StatusWrapper = (props) => {
  const prospectSelector =
    props.stepType === "sms"
      ? getSingleSmsRemindersProspects
      : props.stepType === "call"
        ? getSingleCallRemindersProspects
        : getProspect;

  const actionBtnStatus = useSelector(actionBtnStatusSelector);
  const prospect = useSelector((state) => prospectSelector(state, props.prospectId));
  const dispatch = useDispatch();

  const {
    id,
    ownerVerifiedStatus = false,
    doNotCall = false,
    isQualifiedLead = false,
    isPriority = false,
    wrongNumber = false,
    optedOut = false,
  } = prospect;

  const dncActions = doNotCall
    ? []
    : [
        removeUnreadMessages(id, prospect),
        removeCampaignProspect(id, prospect.hasUnreadSms),
      ];
  const statusList = [
    {
      title: "Verified",
      color: "green",
      icon: "check",
      active: ownerVerifiedStatus === "verified",
      attr: "ownerVerifiedStatus",
      status: actionBtnStatus.ownerVerifiedStatus,
    },
    {
      title: "Block List",
      color: "red",
      icon: "phone-slash",
      active: doNotCall,
      attr: "doNotCall",
      disabled: optedOut,
      status: actionBtnStatus.doNotCall,
      actions: dncActions,
    },
    {
      title: "Priority",
      color: "orange",
      icon: "bolt",
      active: isPriority,
      attr: "isPriority",
      status: actionBtnStatus.isPriority,
    },
    {
      title: "Qualified",
      color: "purple",
      icon: "star",
      active: isQualifiedLead,
      attr: "isQualifiedLead",
      status: actionBtnStatus.isQualifiedLead,
    },
    {
      title: "Wrong Number",
      color: "warning",
      icon: "phone",
      active: wrongNumber,
      attr: "wrongNumber",
      status: actionBtnStatus.wrongNumber,
      actions: [markMessagesAsReadLocally(id, prospect)],
    },
  ];

  const dateTime = getFormattedDateTime(props.time);

  const determineDateOrTime = () => {
    const isSameDay = moment(new Date().toISOString()).isSame(props.time, "day");
    return isSameDay ? dateTime[1] : dateTime[0];
  };

  const leadClass = props.leadStage === "Dead" ? "gray" : "";

  const onStatusChangeClick = (item) => {
    if (item.disabled) return;

    const { attr, actions = [] } = item;
    let value = !prospect[attr];

    if (attr === "ownerVerifiedStatus") {
      const currentValue = prospect[attr];
      value = getNewVerifiedStatus(currentValue);
    }
    dispatch(prospectUpdateStatus(prospect.id, { [attr]: value }, prospect));
    if (actions.length) {
      actions.forEach((action) => dispatch(action));
    }
  };
  const statusActions = statusList.map((item, key) => (
    <Action
      key={key}
      size="sm"
      width="1.7rem"
      height="1.7rem"
      style={{ fontSize: "0.8rem" }}
      data-test={`${item.title.toLowerCase()}-status-action-button`}
      onClick={() => onStatusChangeClick(item)}
      {...item}
    />
  ));
  return (
    <div className="status-wrapper-container d-flex mt-2">
      <span className={`${leadClass} textS mt-1`}>{props.leadStage}</span>
      <div>
        <ButtonsHolster>{statusActions}</ButtonsHolster>
        {props.time ? (
          <span className="gray textS float-right mr-2 mt-1">
            {determineDateOrTime()}
          </span>
        ) : null}
      </div>
    </div>
  );
};

const ButtonsHolster = styled.div`
  display: flex;
  margin: 0 0.8rem 0 0.3rem;
`;

const Action = styled(IconBg)`
  background-color: ${(props) => {
    switch (true) {
      case !!props.disabled:
        return "var(--blueHighlight) !important";
      case !!props.active:
        return `var(--${props.color})`;
      default:
        return `white`;
    }
  }};
  }};
  color: ${(props) => (props.active ? "white" : "var(--gray)")};
  margin-left: 0.4rem;
  cursor: pointer;
  position: relative;
  z-index: 2;
  svg {
    color: ${(props) => (props.active ? "white" : "var(--light)")};
    border: none;
  }
  &:hover {
    background-color: ${(props) => `
      var(--${props.color});
    `};
    opacity: 0.5;
  }
`;

export default StatusWrapper;
