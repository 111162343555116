import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Row, Col } from "reactstrap";

// components
import ProspectStatusActions from "./components/ProspectStatusActions";
import ProspectInformation from "./components/ProspectInformation";
import ProspectPushToCampaign from "./components/ProspectPushToCampaign";
import MessageDetail from "../MessageDetail";
import { LoadingSpinner } from "module/common/components/LoadingSpinner";

// redux related
import { smartStackerActiveProspect } from "module/main/store/uiStore/smartStackerListView/results/selectors";
import { setSmartStackerActiveProspect } from "module/main/store/uiStore/smartStackerListView/results/actions";
import {
  getProspect,
  prospectIsLoading,
} from "module/main/store/prospectStore/selectors";
import { prospectFetchSingle } from "module/main/store/prospectStore/thunks";

// utils
import { ProspectCycleContext } from "module/common/helpers/context";
import usePrevious from "module/common/hooks/usePrevious";
import { hasSMSCampaignsCheck } from "module/common/utils/utils";

const StyledMessageDetail = styled((props) => <MessageDetail {...props} />)`
  min-height: ${(props) => (props.$hasCampaigns ? "30vh" : "20vh")};
  & > .messagesTab {
    opacity: ${(props) => (props.$hasCampaigns ? "1" : "0.2")};
    height: 50vh;
    & > .displayedData {
      position: relative !important;
      & > .messageList {
        padding-top: 1rem !important;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  padding-top: var(--pad4);
`;

const StyledTabItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props.active ? "var(--sherpaBlue)" : "var(--mediumGray)")};
  border-bottom-color: ${(props) =>
    props.active ? "var(--sherpaBlue)" : "var(--mediumGray)"};
  border-bottom-width: 4px;
  border-bottom-style: solid;
  .inactive-tab {
    color: var(--darkNavy);
  }
`;

const StyledLabel = styled.span`
  text-align: left;
  color: var(--gray);
`;
const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-right: var(--pad5);
  padding-left: var(--pad5);
  margin-bottom: var(--pad2);
`;

const SpecialCol = styled(Col)`
  border-right: solid 1px var(--gray);
`;

const StyledProspectContainer = styled.div`
  background-color: var(--ultraGray);
  margin-top: -1.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
  overflow: hidden;
`;

const StyledProspectActions = styled.div`
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`;

const StyledProspectMessagesSection = styled.div`
  height: 50vh;
  overflow-y: auto;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
`;

const StyledLoadingSpinnerContainer = styled.div`
  .loading-spinner {
    vertical-align: middle;
    min-height: 50vh;
  }
`;

const ProspectDetails = () => {
  // local state
  const [activeTab, setActiveTab] = useState("messages");

  // selectors for smart stacker prospects
  const activeProspectId = useSelector(smartStackerActiveProspect);

  // selectors for prospectstore prospects
  const activeProspect = useSelector((state) => getProspect(state, activeProspectId));
  const isFetchingProspectStore = useSelector(prospectIsLoading);

  // previous state
  const prevActiveProspect = usePrevious(activeProspect);
  const prevProspectId = usePrevious(activeProspectId);

  const dispatch = useDispatch();

  const cycleAction = useMemo(
    () => ({
      action: (prospectId) => ({
        onClick: () => {
          dispatch(setSmartStackerActiveProspect(prospectId));
        },
      }),
    }),
    [dispatch]
  );

  // fetch the prospect
  useEffect(() => {
    // only need to fetch the prospect from prospect store if we want to
    // if prospect not found in the store then lets fetch it
    if (activeProspect.partial) {
      dispatch(prospectFetchSingle(activeProspectId));
    }

    //eslint-disable-next-line
  }, [activeProspectId]);

  // TODO: update this when we have a chance
  useEffect(() => {
    // we want to detect a change to the prospect info (as it might affect the the current filterd list)
    // we also need to detect a change IN THE OTHER prospect store
    if (
      activeProspect !== prevActiveProspect &&
      activeProspectId === prevProspectId &&
      prevActiveProspect.id !== 0
    ) {
      //onProspectUpdated && onProspectUpdated();
    }
    //eslint-disable-next-line
  }, [activeProspect, activeProspect]);

  const setYesOrNoText = (value) => (value === false ? "No" : "Yes");

  const renderProspectDetails = () => {
    return (
      <div className="textL mt-3 mb-2">
        <Row xs={4}>
          <SpecialCol>
            <Row xs={1}>
              <StyledCol className="pt-2">
                <StyledLabel className="font-weight-bold">Opted Out:</StyledLabel>
                <span className="font-weight-bold">
                  {activeProspect.optedOut !== null
                    ? setYesOrNoText(activeProspect.optedOut)
                    : "--"}
                </span>
              </StyledCol>
            </Row>
          </SpecialCol>
          <SpecialCol>
            <Row xs={1}>
              <StyledCol className="pt-2">
                <StyledLabel className="font-weight-bold">Block List:</StyledLabel>
                <span className="font-weight-bold">
                  {setYesOrNoText(activeProspect.doNotCall)}
                </span>
              </StyledCol>
              <StyledCol>
                <StyledLabel className="font-weight-bold">Campaigns:</StyledLabel>
                <span className="font-weight-bold">
                  {activeProspect.campaigns ? activeProspect.campaigns.length : "0"}
                </span>
              </StyledCol>
            </Row>
          </SpecialCol>
          <SpecialCol>
            <Row xs={1}>
              <StyledCol className="pt-2">
                <StyledLabel className="font-weight-bold">Age:</StyledLabel>
                <span className="font-weight-bold">
                  {activeProspect.age && activeProspect.age !== null
                    ? activeProspect.age
                    : "--"}
                </span>
              </StyledCol>
              <StyledCol>
                <StyledLabel className="font-weight-bold">Prospect Statuses:</StyledLabel>
                <span className="font-weight-bold">
                  {activeProspect.numOfProspectTags}
                </span>
              </StyledCol>
            </Row>
          </SpecialCol>
          <Col>
            <Row xs={1}>
              <StyledCol className="pt-2">
                <StyledLabel className="font-weight-bold">Skip Traced:</StyledLabel>
                <span className="font-weight-bold">
                  {setYesOrNoText(activeProspect.phone !== null)}
                </span>
              </StyledCol>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  if (!activeProspect) return null;

  return (
    <ProspectCycleContext.Provider value={cycleAction}>
      <StyledProspectContainer>
        <StyledProspectActions className="mb-2 ">
          <ProspectInformation prospect={activeProspect} />
          <ProspectStatusActions prospect={activeProspect} />
        </StyledProspectActions>
        <Container>
          <StyledTabItem
            active={activeTab === "details"}
            onClick={() => setActiveTab("details")}
          >
            <h5
              className={`${
                activeTab === "details" ? "font-weight-bold" : "inactive-tab"
              }`}
            >
              Details
            </h5>
          </StyledTabItem>
          <StyledTabItem
            active={activeTab === "messages"}
            onClick={() => setActiveTab("messages")}
          >
            <h5
              className={`${
                activeTab === "messages" ? "font-weight-bold" : "inactive-tab"
              }`}
            >
              Messages
            </h5>
          </StyledTabItem>
        </Container>
      </StyledProspectContainer>
      {activeTab === "details" && renderProspectDetails()}
      {activeTab === "messages" && (
        <StyledLoadingSpinnerContainer>
          <LoadingSpinner
            isLoading={isFetchingProspectStore}
            size="5rem"
            border="5px"
            className="loading-spinner"
            renderContent={() => (
              <StyledProspectMessagesSection>
                {!hasSMSCampaignsCheck(activeProspect) && (
                  <ProspectPushToCampaign className="pt-3" prospect={activeProspect} />
                )}
                <StyledMessageDetail
                  $hasCampaigns={hasSMSCampaignsCheck(activeProspect)}
                  prospect={activeProspect}
                  prospectId={activeProspectId}
                />
              </StyledProspectMessagesSection>
            )}
          />
        </StyledLoadingSpinnerContainer>
      )}
    </ProspectCycleContext.Provider>
  );
};

export default ProspectDetails;
